<template>
	<div class="main-card">
		<div class="main-card__poster">
			<or-image
				v-for="(image, index) in imgList"
				:key="index"
				:src="image"
				:width="config.size.width ?? 'auto'"
				:height="config.size.height ?? 'auto'"
				:alt="content.title ?? $t('ads.mapRoom')"
			/>
		</div>

		<div class="wrap">
			<div class="room__text">
				<div class="column">
					<span class="tag">{{ content?.plans?.plan[0].plan_type.name }}</span>
					<div style="font-weight: 600">
						> {{ activeMoneySymbol }}{{ actualPrice }}
					</div>
				</div>
			</div>
			<div
				style="
					margin-bottom: 0;
					row-gap: 5px;
					column-gap: 15px;
					max-width: 250px;
				"
				class="room__text room__text--wrap"
			>
				<div style="font-weight: 700" class="room__text--info">
					<or-icon :type="'square'" />
					<span class="room__text--square">
						{{ actualArea }}
						<sup>
							{{ squareTitles[$store.state.areaUnit] }}
						</sup>
					</span>
				</div>
				<div class="room__text--info">
					<or-icon :type="'bathtub'" />
					<span style="font-weight: 700" class="color--main">
						{{ content?.plans?.plan[0].wc_count || 0 }}
					</span>
					<span style="color: #000000">
						{{ $t('ads.baths') }}
					</span>
				</div>
				<div class="room__text--info">
					<or-icon :type="'balcony'" />
					<span
						style="font-weight: 700"
						:class="{
							'color--main': content?.plans?.plan[0].has_balcony,
							'color--gray': !content?.plans?.plan[0].has_balcony
						}"
					>
						{{
							content?.plans?.plan[0].has_balcony
								? $t('general.yes')
								: $t('general.no')
						}}
					</span>
					<span style="color: #000000">
						{{ $t('ads.balcony') }}
					</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { money, simbol } from '@/enums/moneyСollections.js'
import { formatCash } from '@/utils/formatMoney'
import { formatNumber } from '@/utils/formatMoney'
import { mapState } from 'vuex'
import { squareTitles } from '@/enums/areaCollections.js'

const _config = {
	small: {
		class: 'small',
		size: {
			width: '280'
		}
	},
	big: {
		class: 'big',
		size: {
			width: '386',
			height: '347'
		}
	}
}

// TODO:
// Надо разобраться с фотками, надо спросить

export default {
	props: {
		size: {
			type: String,
			default: 'big' // big/small
		},
		type: {
			type: String,
			default: 'main' // main/search
		},
		cardTitle: {
			type: String,
			default: ''
		},
		isLike: {
			type: Boolean,
			default: false
		},
		content: {
			type: Object,
			default: () => ({
				name: '',
				key: '',
				number: 0,
				img: ''
			})
		}
	},
	data() {
		return {
			squareTitles
		}
	},
	computed: {
		...mapState(['favoriteList']),
		isFavorite() {
			return this.favoriteList.find((el) => {
				return el === this.content.slug
			})
		},
		config() {
			return _config[this.size]
		},
		imgList() {
			if (this.content?.plans?.plan[0]) {
				let list = []
				if (this.content?.plans?.plan[0].blueprint?.length > 0)
					list.push(this.content?.plans?.plan[0].blueprint[0])
				else list.push(require(`@/assets/img/components/noPhoto.png`))
				return list
			} else if (this.content?.images === null) {
				let list = []
				list.push(require(`@/assets/img/components/noPhoto.png`))
				return list
			} else return this.content?.images
		},
		price() {
			if (this.content?.price_from) {
				const from = `${simbol[this.$store.state.activeMoney]}${formatCash(
					this.content.price_from[money[this.$store.state.activeMoney]]
				)}`
				let to = ``
				if (this.content?.price_to?.aed > 0)
					to = ` — ${simbol[this.$store.state.activeMoney]}${formatCash(
						this.content.price_to[money[this.$store.state.activeMoney]]
					)}`
				return `${from}${to}`
			} else if (this.content?.price) {
				return `${simbol[this.$store.state.activeMoney]}${formatCash(
					this.content.price[money[this.$store.state.activeMoney]]
				)}`
			} else return ''
		},
		activeMoneySymbol() {
			return simbol[this.$store.state.activeMoney]
		},
		actualPrice() {
			return this.content?.plans
				? formatNumber(
						this.content?.plans?.plan[0].price[
							money[this.$store.state.activeMoney]
						]
				  )
				: 0
		},
		actualArea() {
			return this.content?.plans
				? this.content?.plans?.plan[0].area_to[this.$store.state.areaUnit]
				: 0
		},
		intro() {
			return this.isMainType ? 'Dubai' : this.content.stage?.title
		},
		isMainType() {
			return this.type === 'main'
		},
		settingsCarousel() {
			return {
				itemsToShow: 1,
				mouseDrag: this.imgList?.length > 1,
				touchDrag: this.imgList?.length > 1
			}
		}
	},
	methods: {
		clickLike(status) {
			let cardInfo = {
				slug: this.content.slug
			}
			cardInfo.edit = status
			this.$store.commit('editFavoriteList', cardInfo)
		}
	}
}
</script>

<style lang="scss">
.main-card {
	width: 280px;
	&__poster {
		margin-bottom: 15px;
		height: 215px;

		section {
			height: 215px;

			> div {
				width: 100%;
				height: 100%;

				> ol {
					width: 100%;
					height: 100%;

					> li {
						width: 100%;
						height: 100%;

						> picture {
							width: 100%;
							height: 100%;

							img {
								width: 100%;
								height: 100%;
								object-fit: cover;
							}
						}
					}
				}
			}
		}
		img,
		.skeleton-basic {
			height: 100%;
			object-fit: cover;
		}
	}

	.wrap {
		margin-top: auto;
	}
}

.room {
	&__text {
		margin-bottom: 10px;
		display: flex;
		justify-content: flex-start;
		grid-gap: 15px;

		&--info {
			display: flex;
			align-items: baseline;
			grid-gap: 5px;
		}

		&--wrap {
			flex-wrap: wrap;
		}

		&--square {
			color: $brown-main;

			sup {
				color: #000000;
			}
		}

		.tag {
			padding: 1px 10px;
			border: 1px solid #8080804a;
			white-space: pre-wrap;
			border-radius: 5px;
			font-size: 12px;
		}

		.column {
			display: flex;
			flex-direction: column;
			grid-gap: 6px;
		}
	}
}
</style>
